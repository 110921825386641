export function FileInputEdit(field) {
    let changedItems = [];


    const fileSorted = function (event, params) {
        changedItems = [];
        $.each(params.stack, function (key, value) {
            if(value?.key != null) changedItems.push(value.key);
        });
        console.log(changedItems)

        field.data('fileinput').uploadExtraData.order = changedItems;
    }

    const interceptUpdate = async function (e) {
        e.preventDefault();
        e.stopPropagation();
        const img = $('#images');

        $.each(changedItems, function (key, value) {
            img.data('fileinput').uploadExtraData[`order[${key}]`] = value;
        })
    }

    const update = function (updateRoute, id, tableName) {
        return new Promise(async (resolve, reject) => {
            const img = $('#images');

            $('.kv-file-upload.btn.btn-sm.btn-kv.btn-default.btn-outline-secondary').each(function (item) {
                $(this).click();
            });

            if (img.data('fileinput').uploadExtraData.order !== undefined) {
                await $.ajax({
                    url: updateRoute,
                    headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                    type: 'PUT',
                    data: {
                        id: id,
                tableName: tableName,
                    order: img.data('fileinput').uploadExtraData.order
            }
            }).then(result => resolve(result))
            } else resolve('resolved');
        });
    };


    return {
        Intercept: interceptUpdate,
        Update: update,
        changedItems: changedItems,
        FileSorted: fileSorted
    }
}
