import {CustomFileInput} from "./fileinput";

require('bootstrap-select');

import Sortable from 'bootstrap-fileinput/js/plugins/sortable';
import piexif from 'piexifjs';


try {
    window.Sortable = Sortable;
    window.piexif = piexif;
    window.fileinput = require('bootstrap-fileinput');
    window.CustomFileinput = new CustomFileInput();
} catch (e) {
    console.error(e)
}