

window.prototype = this;
require('./bootstrap');
require('./bootstrap-multi-level-dropdown');
require('./imports');
require('./orderChanger');
window.initTiny = function(options) {
    const config = _.defaultsDeep(options, {
        selector: '#text',
        plugins: 'advlist autolink lists link charmap preview pagebreak table autoresize autosave help quickbars wordcount',
        paste_data_images: true,
        toolbar: [
            'file | edit | view | insert | format | restoredraft | help',
            'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | table bullist numlist outdent indent | link'
        ],
        toolbar_mode: 'floating',
        contextmenu: false
    })
    tinymce.init(config);
}
//
import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';

window.FilePond = FilePond;
FilePond.registerPlugin(FilePondPluginImagePreview);
FilePond.registerPlugin(FilePondPluginImageExifOrientation);
FilePond.registerPlugin(FilePondPluginFileRename);
FilePond.registerPlugin(FilePondPluginImageEdit);

FilePond.setOptions({
    storeAsFile: true,
    allowImagePreview: true,
    allowImageExifOrientation: true,
    allowReorder: true,
})
