import _ from "lodash";
import {FileInputEdit} from "./fileinput-edit";

export function CustomFileInput() {
    let field = $('#images');
    let edit = new FileInputEdit(field);
    let defaultConfig = {
        captionClass: 'w-auto',
        inputGroupClass: 'bg-light ms-1',
        mainClass: 'bg-light rounded',
        previewClass: 'bg-light',
        deleteExtraData: {'_method': 'DELETE'},
        browseOnZoneClick: true,
        showUpload: false,
        dropZoneEnabled: true,
        initialPreviewAsData: true,
        maxImageWidth: 200,
        draggable: true,
        fileActionSettings: {
            showZoom: false,
            showUpload: false,
        },
        ajaxSettings: {
            method: 'POST',
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
        },
        resizeImage: true
    }

    const isset = (variable) => variable !== undefined && variable !== null;

    const reset = (configuration) => field.fileinput('destroy').fileinput(setConfig(configuration));

    const setConfig = (configuration) => {
        const excluded = ["initialPreview", "initialPreviewConfig"];
        let conf = _.defaultsDeep(defaultConfig, configuration);
        _.forEach(excluded, function(excl) {
            if(_.has(conf, excl)) {
                _.set(conf, excl, configuration[excl]);
            }
        });

        return conf;
    }

    async function fetchItem(item) {
        console.log('original url: ' + item.original_url)
        await fetch(item.original_url)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], item.name, blob)
                field.fileinput('addToStack', file);
            });
    }

    const init = (initializer, configuration) => {
        if (isset(initializer)) field = initializer;
        field.fileinput(setConfig(configuration));
    }

    return {
        Field: field,
        Fetch: fetchItem,
        Init: init,
        Edit: edit,
        Reset: reset,
    }
}
