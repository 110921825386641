require('jquery-ui/ui/core')
require('jquery-ui/ui/widget')
require('jquery-ui/ui/widgets/mouse')
require('jquery-ui/ui/widgets/sortable')

let updateIndex = function (e, ui) {
    const parent = $('.order-container.parent');
    let index = 0;
    parent.children('li[is-child=false]').each(function() {
        const item = $(this);
        item.find('input').attr('value', index);
        index++;
        const children = item.find('li[is-child=true]');
        if(children.length > 0) {
            children.each(function() {
                const child = $(this);
                child.find('input').attr('value', index);
                index++;
            })
        }
    })
};

document.addEventListener("DOMContentLoaded", function () {
    $(".order-container").sortable({
        stop: updateIndex,
        axis: 'y',
        cursor: 'move',
        opacity: 0.5
    });
})