(function ($bs) {
    const className = 'has-child-dropdown-show';
    $bs.Dropdown.prototype.toggle = (_original => function () {
        document.querySelectorAll('.' + className).forEach(e => e.classList.remove(className));

        let dropdown = this._element.closest('.dropdown').parentNode.closest('.dropdown');
        for (; dropdown && dropdown !== document; dropdown = dropdown.parentNode.closest('.dropdown')) {
            dropdown.classList.add(className);
        }
        return _original.call(this);
    })($bs.Dropdown.prototype.toggle);

    document.querySelectorAll('.dropdown').forEach(function (dropdown) {
        dropdown.addEventListener('hide.bs.dropdown', function (e) {
            if (this.classList.contains(className)) {
                this.classList.remove(className);
                e.preventDefault();
            }
            e.stopPropagation(); // do not need pop in multi level mode
        });
    });

    // for hover
    document.querySelectorAll('.dropdown-hover, .dropdown-hover-all .dropdown')
        .forEach(function (dropdown) {
            dropdown.addEventListener('mouseenter', function (e) {
                let toggle = e.target.querySelector(':scope>[data-bs-toggle="dropdown"]');
                if (!toggle.classList.contains('show')) {
                    $bs.Dropdown.getOrCreateInstance(toggle).toggle();
                    dropdown.classList.add(className);
                    $bs.Dropdown.clearMenus();
                }
            });
            dropdown.addEventListener('mouseleave', function (e) {
                let toggle = e.target.querySelector(':scope>[data-bs-toggle="dropdown"]');
                if (toggle.classList.contains('show')) {
                    $bs.Dropdown.getOrCreateInstance(toggle).toggle();
                }
            });
        });
})(bootstrap);
